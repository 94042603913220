import { render, staticRenderFns } from "./OrderDetailComponent.vue?vue&type=template&id=c2a93bca&scoped=true"
import script from "./OrderDetailComponent.vue?vue&type=script&lang=js"
export * from "./OrderDetailComponent.vue?vue&type=script&lang=js"
import style0 from "./OrderDetailComponent.vue?vue&type=style&index=0&id=c2a93bca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a93bca",
  null
  
)

export default component.exports