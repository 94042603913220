'use strict';

const setLoading = (state, loadingToSet) => {
  state.loading = loadingToSet;
};

const setIsOrderLoading = (state, loadingToSet) => {
  state.isOrderLoading = loadingToSet;
};

export default {
  setLoading,
  setIsOrderLoading,
};
