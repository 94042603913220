<template>
  <v-container>
    <h1
      color="primaryVariant1"
      class="ml-3 text-center"
      style="margin-top: 16px; margin-bottom: 4px"
    >
      Orden de trabajo
    </h1>
    <v-row>
      <v-col cols="5">
    <span style="font-weight: bold; font-size: x-large">Numero de orden: </span>
    <span style="font-size: x-large; margin-bottom: 8px">{{
      order.orderNumber
    }}</span>
    </v-col>
    <v-col cols="7" align="right">
    <v-btn 
      class="primary"
      style="margin-right: auto;"
      @click="downloadPDF(order.id, false)"
      >Descargar PDF</v-btn
    >
    </v-col>
  </v-row>
    <v-divider
      class="border-opacity-100"
      style="border-width: 1px; border-color: #b71c1c; margin-top: 16px"
    ></v-divider>
    <v-row style="margin-left: 4px; margin-top: 4px">
      <v-col cols="6">
        <h1 class="text-center" color="primary">Cliente</h1>
        <p>
          <span style="font-weight: bold">Nombre:</span> {{ order.client.name }}
        </p>
        <p>
          <span style="font-weight: bold">Apellido:</span>
          {{ order.client.lastName }}
        </p>
        <p>
          <span style="font-weight: bold">Telefono:</span>
          {{ order.client.phone }}
        </p>
        <p><span style="font-weight: bold">CI:</span> {{ order.client.ci }}</p>
      </v-col>

      <v-divider
        class="border-opacity-100 mb-3 mt-n1"
        style="border-width: 1px; border-color: #b71c1c"
        vertical
      ></v-divider>

      <v-col cols="6">
        <h1 class="text-center" color="primary">Motor</h1>
        <p>
          <span style="font-weight: bold">Marca:</span>
          {{ this.detail.item.specification.brand }}
        </p>
        <p>
          <span style="font-weight: bold">Motor:</span>
          {{ this.detail.item.specification.engine }}
        </p>
        <p>
          <span style="font-weight: bold">Cilindrada:</span>
          {{ this.detail.item.specification.numberOfCylinders }}
        </p>
      </v-col>
    </v-row>

    <v-divider
      :thickness="7"
      class="border-opacity-100 mb-16"
      style="border-width: 1px; border-color: #b71c1c"
    ></v-divider>

    <h1 color="primaryVariant1" class="ml-3 mb-4 text-center">
      Estado de la orden
    </h1>

    <v-stepper v-if="mobile" vertical alt-labels v-model="whatStepIsIt">
        <template v-for="n in allOrderStatuses.length">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="whatStepIsIt > n"
            :step="n"
          >
            <p align="center">{{ orderStatusName(n) }}</p>
          </v-stepper-step>
        </template>
    </v-stepper>

    <v-stepper v-else alt-labels v-model="whatStepIsIt">
      <v-stepper-header>
        <template v-for="n in allOrderStatuses.length">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="whatStepIsIt > n"
            :step="n"
          >
            <p align="center">{{ orderStatusName(n) }}</p>
          </v-stepper-step>

          <v-divider v-if="n !== allOrderStatuses.length" :key="n"></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>

    <h1 color="primaryVariant1" class="ml-3 mb-4 mt-16 text-center">
      Partes & Repuestos
    </h1>

    <v-card class="mb-7">
      <v-tabs
        v-model="tabParts"
        background-color="backgroundColor"
        center-active
        show-arrows
        grow
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab :key="parts">
          <span style="color: primary">Partes</span>
        </v-tab>
        <v-tab :key="spareParts">
          <span style="color: primary">Repuestos</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabParts">
        <v-tab-item :key="parts">
          <v-card-text>
            <v-window>
              <v-window-item>
                <li v-for="part in this.partsOfOrder" :key="part.id">
                  <span style="font-weight: bold">{{ part.name }} ( </span>
                  <span v-for="subPart in part.subParts" :key="subPart.name">
                    *{{ subPart.name }}
                  </span>
                  <span v-if="!part.subParts"
                    >No se seleccionaron sub-partes
                  </span>
                  <span>)</span>
                </li>
                <p v-if="this.detail.item.parts.length === 0">
                  <span style="font-weight: bold">No se dejaron partes</span>
                </p>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-tab-item>
        <v-tab-item :key="spareParts">
          <v-card-text>
            <v-window>
              <v-window-item>
                <li
                  v-for="sparePart in this.sparePartsOfOrder"
                  :key="sparePart.name"
                >
                  <span style="font-weight: bold"
                    >{{ sparePart.quantity }}x
                  </span>
                  <span style="font-weight: bold">{{ sparePart.name }}</span>
                  <span> ({{ sparePart.measure }} </span>
                  <span>{{ sparePart.unitOfMeasure }}</span>
                  <span>)</span>
                </li>
                <p v-if="this.detail.item.spareParts.length === 0">
                  <span style="font-weight: bold"
                    >No se requieren repuestos</span
                  >
                </p>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <h1 color="primaryVariant1" class="ml-3 mb-4 mt-16 text-center">
      Servicios
    </h1>
    <v-card>
      <v-tabs
        v-model="tabServices"
        background-color="backgroundColor"
        center-active
        show-arrows
        grow
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="service in servicesOfOrder" :key="service.id">
          <span style="color: primary">{{ service.name }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabServices">
        <v-tab-item v-for="service in servicesOfOrder" :key="service.id">
          <v-card-text>
            <v-window>
              <v-window-item>
                <li
                  v-for="subService in service.subServices"
                  :key="subService.id"
                >
                  <span style="font-weight: bold">{{ subService.name }}</span>
                </li>
                <p v-if="service.id === 'quickService'">
                  <span style="font-weight: bold">Servicio rapido</span>
                </p>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BUSINESS_ID_RECTIFICATION } from '@/constants/ConfigurationConstants';
import { CLIENT_API_URL } from '../../../constants/Environment';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import axios from 'axios';

export default {
  name: 'OrderDetailComponent',

  props: ['order'],

  data: () => ({
    steps: 5,
    e1: 1,
    tabParts: null,
    tabServices: null,
    parts: null,
    spareParts: null,
  }),

  computed: {
    ...mapGetters('orderStatuses', [
      'getOrderStatusById',
      'getAllOrderStatuses',
    ]),

    detail() {
      return JSON.parse(this.order.detail);
    },

    partsOfOrder() {
      return JSON.parse(this.order.detail).item.parts;
    },

    sparePartsOfOrder() {
      return JSON.parse(this.order.detail).item.spareParts;
    },

    allOrderStatuses() {
      return this.getAllOrderStatuses;
    },

    servicesOfOrder() {
      return JSON.parse(this.order.detail).services;
    },

    whatStepIsIt() {
      return (
        this.getAllOrderStatuses.findIndex(
          (ordStatus) => ordStatus.id === this.order.orderStatus.id
        ) + 1
      );
    },

    mobile() {
      return (this.$vuetify.breakpoint.xs)
    },
  },

  created() {
    this.retrieveAllBusinessAndSpecialOrderStatuses(BUSINESS_ID_RECTIFICATION);
  },

  methods: {
    ...mapActions('orderStatuses', [
      'retrieveAllBusinessAndSpecialOrderStatuses',
    ]),

    orderStatusName(index) {
      return this.getAllOrderStatuses[index - 1].name;
    },

    async printPDF(orderId, isWithDiscount) {
      let objectToPrint = await this._fetchObjectToPrint(
        orderId,
        'order-print',
        isWithDiscount
      );
      var opt = {
        html2canvas: { y: 0, scrollY: 0 },
        filename: objectToPrint.fileName + '.pdf',
      };
      html2pdf()
        .set(opt)
        .from(objectToPrint.htmlData)
        .outputPdf()
        .then(function (pdf) {
          printJS({
            printable: btoa(pdf),
            type: 'pdf',
            base64: true,
          });
        });
    },

    async downloadPDF(orderId, isWithDiscount) {
      let objectToPrint = await this._fetchObjectToPrint(
        orderId,
        'order-print',
        isWithDiscount
      );
      var opt = {
        image: { type: 'png' },
        html2canvas: { scale:2, x:0, y:0, scrollX:0, scrollY: 0 },
        jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'},
        filename: objectToPrint.fileName + '.pdf',
      };

      html2pdf()
        .set(opt)
        .from(objectToPrint.htmlData)
        .toImg()
        .outputPdf().save();
    },

    async _fetchObjectToPrint(orderId, url, isWithDiscount) {
      let objectToPrintData;
      try {
        objectToPrintData = await axios.get(
          `${CLIENT_API_URL}/orders/${orderId}/${url}?discounts=${isWithDiscount}`
        );
      } catch (error) {
        console.error(
          'There was an error while retrieving the object to print' + error
        );
      }
      return objectToPrintData.data;
    },
  },
};
</script>
<style scoped>
@import './ScopedStyles.css';
</style>