// The block below is for the UAT environment
/*
export const CLIENT_API_URL =
  'https://engine-rebuilding-client-api-uat.azurewebsites.net/api';
export const ITEM_API_URL =
  'https://engine-rebuilding-item-api-uat.azurewebsites.net/api';
export const PRODUCT_API_URL =
  'https://engine-rebuilding-product-api-uat.azurewebsites.net/api';
export const SERVICE_API_URL =
  'https://engine-rebuilding-service-api-uat.azurewebsites.net/api';
export const USER_API_URL =
  'https://engine-rebuilding-user-api-uat.azurewebsites.net/api';
*/

// The block below is for the development environment
/*
export const CLIENT_API_URL =
  'https://engine-rebuilding-client-api-dev.azurewebsites.net/api';
export const ITEM_API_URL =
  'https://engine-rebuilding-item-api-dev.azurewebsites.net/api';
export const PRODUCT_API_URL =
  'https://engine-rebuilding-product-api-dev.azurewebsites.net/api';
export const SERVICE_API_URL =
  'https://engine-rebuilding-service-api-dev.azurewebsites.net/api';
export const USER_API_URL =
  'https://engine-rebuilding-user-api-dev.azurewebsites.net/api';
*/

// The block below is for the Production environment

export const CLIENT_API_URL =
  'https://engine-rebuilding-client-api-prod.azurewebsites.net/api';
export const ITEM_API_URL =
  'https://engine-rebuilding-item-api-prod.azurewebsites.net/api';
export const PRODUCT_API_URL =
  'https://engine-rebuilding-product-api-prod.azurewebsites.net/api';
export const SERVICE_API_URL =
  'https://engine-rebuilding-service-api-prod.azurewebsites.net/api';
export const USER_API_URL =
  'https://engine-rebuilding-user-api-prod.azurewebsites.net/api';