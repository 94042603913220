import Vue from 'vue';
import VueRouter from 'vue-router';
//import HomeView from '../views/home/HomeView.vue';
import OrdersView from '../views/orders/OrdersView.vue';
import LoginView from '../views/login/LoginView.vue';
import OrdersListView from '../views/orders/orders-list/OrdersListView.vue';

Vue.use(VueRouter);

const routes = [
  /*{
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: {
      title: 'USA Autopartes',
    },
  },*/
  {
    path: '/',
    name: 'LoginView',
    component: LoginView,
    meta: {
      title: 'USA Autopartes',
    },
  },
  {
    path: '/orders',
    name: 'OrdersView',
    component: OrdersView,
    meta: {
      title: 'USA Autopartes',
    },
  },
  {
    path: '/orders/orders-consult',
    name: 'OrdersListView',
    component: OrdersListView,
    meta: {
      title: 'USA Autopartes',
    },
  },
];

const router = new VueRouter({ mode: 'history', routes });

export default router;
