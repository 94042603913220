import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import axios from 'axios';

import orders from './modules/orders';
import orderStatuses from './modules/orderStatuses';
import loading from './modules/loading';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const store = {
  modules: {
    orders,
    orderStatuses,
    loading,
  },
};

export default new Vuex.Store(store);
