'use strict';

const getOrderStatusById = (state) => (orderStatusId) => {
  return Object.assign(
    {},
    state.orderStatuses.find((os) => os.id === orderStatusId)
  );
};

const getAllOrderStatuses = (state) => {
  return state.orderStatuses;
};

export default {
  getOrderStatusById,
  getAllOrderStatuses,
};
