'use strict';

const getLoading = (state) => {
  return state.loading;
};

const getIsOrderLoading = (state) => {
  return state.isOrderLoading;
};

export default {
  getLoading,
  getIsOrderLoading,
};
