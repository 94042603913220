<template>
  <v-container>
    <h1 class="ml-3 primary--text">Tus Ordenes</h1>
    <v-data-table
      :headers="headers"
      :items="getOrders"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
        itemsPerPageText: 'Filas por pagina',
      }"
      :items-per-page="10"
      class="elevation-1 ma-8"
      :loading="getIsOrderLoading"
      :loading-text="'Cargando... Por favor espere'"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary" @click="seeOrderDetail(item)">
          mdi-eye-outline
        </v-icon>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de
        {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <p>No hay ordenes</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrdersListView',

  data() {
    return {
      search: '',
      keyword: '',
      isSearching: false,
      isDisabled: true,
      options: {},
    };
  },

  watch: {
    options: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.retrieveAllClients(this.options);
        }
      },
      deep: true,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: 'Número de Orden',
          value: 'orderNumber',
          align: 'center',
          sortable: true,
          class: 'primary--text',
        },
        {
          text: 'Estado',
          value: 'orderStatus.name',
          align: 'center',
          sortable: true,
          class: 'primary--text',
        },
        {
          text: 'Acciones',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'primary--text',
        },
      ];
    },

    ...mapGetters('orders', ['getOrders', 'getOrder']),
    ...mapGetters('loading', ['getIsOrderLoading']),
  },

  methods: {
    ...mapMutations('orders', ['setOrder']),

    seeOrderDetail(order) {
      this.setOrder(order);
      this.$router.push('/orders');
    },
  },
};
</script>
