<!--i18n src="./resources/locales.json"></i18n-->

<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Consultar Ordenes</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                v-model="clientId"
                :label="'C.I o NIT'"
                :type="getTypePassword()"
                :append-icon="getAppendIconForPassword()"
                @click:append="showPassword = !showPassword"
                prepend-icon="mdi-lock"
                :error="isThereNoOrders"
                @keyup.enter="consult"
              />
              <p v-if="this.isThereNoOrders === true">
                <span class="default-alert">
                  El usuario no tiene ordenes.
                </span>
              </p>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @keyup.enter="consult"
              @click="consult"
              :loading="isLoading()"
              :disabled="isLoading()"
              >Ver mis ordenes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LoginView',

  data: () => ({
    valid: true,
    clientId: '',
    showPassword: false,
    isThereNoOrders: false,
  }),

  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('orders', ['getOrders']),
  },

  watch: {
    clientId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.clientId == newVal;
        }
      },
    },
  },

  methods: {
    ...mapActions('loading', ['setLoadingOnFalse', 'setLoadingOnTrue']),
    ...mapActions('orders', ['retrieveOrdersOfClient']),

    validate() {
      this.$refs.form.validate();
    },

    async consult() {
      this.setLoadingOnTrue();
      await this.retrieveOrdersOfClient({ clientId: this.clientId });
      if (this.getOrders.length !== 0) {
        this.setLoadingOnFalse();
        this.$router.push('/orders/orders-consult');
      } else {
        this.isThereNoOrders = true;
        this.setLoadingOnFalse();
      }
    },

    getTypePassword() {
      return this.showPassword ? 'text' : 'password';
    },

    getAppendIconForPassword() {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },

    isThereNoOrdersForUser() {
      let answer = false;
      if (this.getOrders.length === 0) {
        answer = true;
      }
      return answer;
    },

    isUserStorageRoleIdCorrect(userStorageRoleId) {
      return userStorageRoleId !== '' && userStorageRoleId !== null;
    },

    isUserStorageRoleIdIncorrect() {
      return this.userStorageRoleId === null;
    },

    isLoading() {
      return this.getLoading;
    },
  },
};
</script>
