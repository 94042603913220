<template>
  <v-app>
    <v-app-bar app flat dense dark height="65">
      <v-toolbar flat dense v-if="$vuetify.breakpoint.mdAndUp">
        <!--v-img
          :src="require('@/assets/logo.png')"
          max-height="55px"
          max-width="55px"
        >
        </v-img-->

        <!--v-toolbar-title style="font-size: 30px"
          >USA Autopartes</v-toolbar-title
        -->

        <v-spacer></v-spacer>

        <!--v-toolbar-items>
          <v-btn icon width="100px" @click="scrollMeTo('home')">Inicio</v-btn>
          <v-btn icon width="100px" @click="scrollMeTo('about-us')"
            >Nosotros</v-btn
          >
          <v-btn icon width="100px" @click="scrollMeTo('services')"
            >Servicios</v-btn
          >
        </v-toolbar-items-->
      </v-toolbar>
      <v-toolbar flat dense v-if="$vuetify.breakpoint.smAndDown">
        <v-app-bar-nav-icon
          style="margin-left: -30px"
          @click="drawer = true"
        ></v-app-bar-nav-icon>
        <div style="width: 100%">
          <div style="justify-content: center; display: flex">
            <!--v-img
              :src="require('@/assets/logo.png')"
              max-height="55px"
              max-width="55px"
            >
            </v-img-->
          </div>
        </div>
      </v-toolbar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>

    <v-footer inset dark padless style="margin-top: 24px">
      <div style="display: flex; width: 100%; justify-content: center">
        <div style="display: flex; width: 50%">
          <!--v-row
            justify="center"
            style="display: flex; margin-top: 64px; margin-bottom: 64px"
          >
            <div style="width: 25%; display: flex">
              <v-col style="width: 100%">
                <v-row style="padding-bottom: 16px; font-weight: bold"
                  >Contactanos</v-row
                >
                <v-row>Cochabamba</v-row>
                <v-row>Santivañez #0385 casi esquina, Tumusla.</v-row>
                <v-row>Telefono: 76999027</v-row>
              </v-col>
            </div>
            <div style="width: 25%; display: flex; padding-left: 64px">
              <v-col style="width: 100%">
                <v-row style="padding-bottom: 16px; font-weight: bold"
                  >Siguenos</v-row
                >
                <v-row>
                  <v-icon size="35" style="padding-right: 8px"
                    >mdi-facebook</v-icon
                  >
                  <v-icon size="35" style="padding-right: 8px"
                    >mdi-instagram</v-icon
                  >
                  <v-icon size="35">mdi-twitter</v-icon>
                </v-row>
              </v-col>
            </div>
            <div style="width: 50%; display: flex; padding-left: 64px">
              <v-col style="width: 25%">
                <v-row style="padding-bottom: 16px; font-weight: bold"
                  >Acerca de nosotros</v-row
                >
                <v-row style="text-justify: inter-word"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  fermentum fringilla augue ac consequat. Nulla viverra dictum
                  dui viverra pellentesque. Proin ultrices ligula sit amet leo
                  pretium, quis tempus dolor convallis.</v-row
                >
              </v-col>
            </div>
          </v-row-->
          <!--v-divider></v-divider-->
        </div>
      </div>
      <div style="display: flex; width: 100%; justify-content: center">
        <div style="display: flex">
          <v-divider></v-divider>
          <v-row
            justify="center"
            style="display: flex; margin-top: 8px; margin-bottom: 64px"
          >
            <!--strong>USA Autopartes</strong--></v-row
          >
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
  }),

  methods: {
    scrollMeTo(id) {
      const yOffset = -100;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
};
</script>
