'use strict';

const setLoadingOnTrue = ({ commit }) => {
  commit('setLoading', true);
};

const setLoadingOnFalse = ({ commit }) => {
  commit('setLoading', false);
};

const setIsOrderLoading = ({ commit }, loadingToSet) => {
  commit('setIsOrderLoading', loadingToSet);
};

export default {
  setLoadingOnTrue,
  setLoadingOnFalse,
  setIsOrderLoading,
};
