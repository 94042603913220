'use strict';

const getOrder = (state) => {
  return Object.assign({}, state.order);
};

const getOrders = (state) => {
  return state.orders;
};

export default {
  getOrder,
  getOrders,
};
