'use strict';

const setOrder = (state, order) => {
  state.order = order;
};

const setOrders = (state, orders) => {
  state.orders = orders;
};

export default {
  setOrder,
  setOrders,
};
