<template>
  <OrderDetailComponent :order="getOrder" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderDetailComponent from '@/components/reusable-components/order-detail/OrderDetailComponent.vue';

export default {
  name: 'OrdersView',

  components: {
    OrderDetailComponent,
  },

  computed: {
    ...mapGetters('orders', ['getOrder']),
  },

  created() {
    this.retrieveOrderFromQR({
      orderNumber: this.$route.query.orderNumber,
      year: this.$route.query.year,
      secret: this.$route.query.secret,
    });
  },

  methods: {
    ...mapActions('orders', ['retrieveOrderFromQR']),
  },
};
</script>
