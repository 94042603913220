'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveOrderFromQR = async ({ commit }, params) => {
  try {
    const { orderNumber, year, secret } = params;
    if (orderNumber && year && secret) {
      await axios
        .get(
          `${CLIENT_API_URL}/orders/from-qr?orderNumber=${orderNumber}&year=${year}&secret=${secret}`
        )
        .then((response) => {
          commit('setOrder', response.data);
        });
    }
  } catch (error) {
    console.error('There was an error while retrieving the order' + error);
  }
};

const consultOrder = async ({ commit }, data) => {
  try {
    const { orderNumber, password } = data;
    await axios
      .get(
        `${CLIENT_API_URL}/orders/from-password?orderNumber=${orderNumber}&clientPassword=${password}`
      )
      .then((response) => {
        commit('setOrder', response.data);
      });
  } catch (error) {
    console.error('There was an error while retrieving the order' + error);
  }
};

const retrieveOrdersOfClient = async ({ commit }, params) => {
  try {
    const { clientId } = params;
    await axios
      .get(`${CLIENT_API_URL}/clients/${clientId}/orders-by-ci`)
      .then((response) => {
        commit('setOrders', response.data);
      });
  } catch (error) {
    console.error(
      'There was an error while retrieving the orders of the client' + error
    );
  }
};

export default {
  retrieveOrderFromQR,
  consultOrder,
  retrieveOrdersOfClient,
};
