'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllBusinessAndSpecialOrderStatuses = async (
  { commit },
  businessId
) => {
  try {
    await axios
      .get(
        `${CLIENT_API_URL}/order-statuses/business-with-specials/${businessId}`
      )
      .then((response) => {
        commit('setAllOrderStatuses', response.data);
      });
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

export default {
  retrieveAllBusinessAndSpecialOrderStatuses,
};
